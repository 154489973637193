import '../App.css';

const productTypes = [
  { 
    name: 'Websites', 
    href: '/websites',
    image: '/kanejackson.png',
    description: "Build an exceptional web presence that elevates your brand, engages your audience, and drives sustainable growth." 
  }, { 
    name: 'Applications', 
    href: '/solutions',
    image: 'stockman.jpg',
    description: `We specialize in creating dynamic and innovative web-apps tailored to meet your unique business needs. \n \
                  Our solutions come with a focus on functionality, user experience, and driving measurable results.`
  },
]

function Products() {
  return (
    <div className="App">

      <div className="relative px-6 mt-28 lg:px-8 shadow-lg">
        <div className="mx-auto max-w-6xl pb-24">
          {productTypes.map((item) => (
            <div 
              key={item.name}
              className="background shadow-md rounded-2xl border border-gray-300 mb-16 p-2 text-left bg-white flex flex-col-reverse justify-between lg:odd:flex-row lg:even:flex-row-reverse lg:even:justify-end whitespace-pre-line">
              <div className="m-12">
                <h2 className="text-4xl font-bold tracking-tight">{item.name}</h2>
                <p className="my-6 text-lg leading-8 text-gray-600">{item.description}</p>
                <a
                  href={item.href}
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  See more <span aria-hidden="true">→</span>
                </a>
              </div>
              <img
                className="w-full right-0 rounded-xl lg:w-2/5"
                src={item.image}
                alt=""
              />
            </div>
          ))}
        </div>
      </div>

    </div>
  );
}

export default Products;

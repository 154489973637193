import '../App.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Tooltip from '../components/Tooltip';


const technologies = [
  { 
    name: "Node",
    href: 'https://kanejackson.com',
    image: '/Node-logo.png',
  }, { 
    name: "React",
    href: 'https://kanejackson.com',
    image: '/React-logo.png',
  }, { 
    name: 'Tailwind', 
    href: '/solutions',
    image: '/Tailwind-logo.png'
  }, { 
    name: 'Socket.io', 
    href: '/solutions',
    image: '/Socketio-logo.png'}
]

function About() {
  return (
    <div className="bg-white">
      <Header></Header>

      <div className="relative px-6 pt-28 lg:px-8 background shadow-lg">
        <div className='mx-auto max-w-4xl '>
          <h2 className="text-4xl mt-16 font-bold tracking-tight">Technologies</h2>
          <p className="text-lg my-8 leading-8 text-gray-600">
            We work with a variety of modern web technologies to produce quality solutions through an agile process.
          </p>

          <div className="mx-auto py-24 flex flex-row flex-wrap justify-between items-center">
            {technologies.map((item) => (
              <div 
                className="rounded-2xl border-gray-300 mb-16 p-2 text-left items-center"
                key={item.name}
              >
                <Tooltip message={item.name}>
                  <img
                    className="h-20"
                    src={item.image}
                    alt=""
                  />
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}

export default About;

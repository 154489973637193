// import { useState } from 'react'
// import { Dialog } from '@headlessui/react'

import Header from './Header';

export default function Hero() {
  

  return (
    <div className="bg-white">
      <Header></Header>



      <div className="relative px-6 lg:px-8 background shadow-md border border-gray-300">
        <img
          className="flex items-center h-20 opacity-10 w-auto absolute z-10 bottom-0 lg:h-60"
          src="/Rook_watermark.png"
          alt=""
        />
        <div className="mx-auto max-w-2xl pt-32 pb-24 sm:pt-48 lg:pt-56">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Moving businesses onto the web
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Rook Technologies provides quality web-based solutions to meet businesses needs.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/contact"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Get in touch
              </a>
              <a href="/about" className="text-sm font-semibold leading-6 text-gray-900">
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

import '../App.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const websites = [
  { 
    name: 'Personalised pages', 
    href: 'https://kanejackson.com',
    image: '/kanejackson.png',
    description: "A lil site for a compsci student" 
  }, { 
    name: 'Web 2', 
    href: '/solutions',
    description: "Another lil website hehe"},
  { name: 'Websites', href: '/websites' },
  { name: 'Contact', href: '/contact' },
]

function Websites() {
  return (
    <div className="App">
      <Header></Header>

      <div className="relative px-6 lg:px-8 background shadow-lg">
        <div className="mx-auto max-w-6xl pt-32 pb-24 sm:pt-48 lg:pt-36">
          {websites.map((website) => (
            <div className="rounded-2xl shadow-lg border border-gray-300 mb-16 p-2 text-left bg-white flex flex-col-reverse lg:flex-row justify-between">
              <div className="w-200 m-12">
                <h2 className="text-4xl font-bold tracking-tight">{website.name}</h2>
                <p className="my-6 text-lg leading-8 text-gray-600">{website.description}</p>
                <a
                  href={website.href}
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Visit website <span aria-hidden="true">→</span>
                </a>
              </div>
              <img
                className="w-full right-0 rounded-xl lg:w-2/5"
                src={website.image}
                alt=""
              />
            </div>
          ))}
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}

export default Websites;
